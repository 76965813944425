import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Global } from "@emotion/react";
// import _get from "lodash.get";

import DetailPageList from "app/components/DetailPageList";
import { invertColors } from "app/styles/utils";

const ABOUT_QUERY = graphql`
  query AboutPageQuery {
    entry: craftAboutAboutEntry {
      id
      title
      displayTitle
      exploration {
        ... on Craft_exploration_BlockType {
          id
          sectionTitle
          pages {
            id
            ...SiteDetailPage
            ...AboutPage
          }
        }
      }
    }
  }
`;

const AboutPage = (props) => {
  const data = useStaticQuery(ABOUT_QUERY);

  return (
    <>
      <Global styles={invertColors} />
      <DetailPageList {...props} data={data} about />
    </>
  );
};

export default AboutPage;
